"use client";

import { Button } from "primereact/button";
import Image from "next/image";
import { useRouter } from "next/navigation";
import User from "@/types/user";

interface LandingProps {
  user: User;
}

const Landing = ({ user }: LandingProps) => {
  const router = useRouter();

  return (
    <div className="surface-0 flex justify-content-center p-component">
      <div id="home" className="landing-wrapper overflow-hidden">
        <div
          id="hero"
          className="flex flex-column pt-4 px-4 lg:px-8 overflow-hidden gradient-background"
        >
          <div className="mx-4 md:mx-8 mt-0 md:mt-4">
            <h1 className="text-2xl font-bold text-gray-900 line-height-2">
              <span className="font-light">
                Unlock winning potential with expert projections for player
                props on{" "}
              </span>
              Underdog and PrizePicks.
              <span className="font-light block">
                Elevate your betting strategy with data-driven insights tailored
                for success
              </span>
            </h1>
            <p className="font-normal text-lg line-height-3 md:mt-3 text-gray-700">
              Dive into a world of precise predictions, tailored to give you the
              edge in your bets on Underdog and PrizePicks platforms.
            </p>
            <Button
              type="button"
              label="Get Started"
              rounded
              className="text-xl border-none mt-3 bg-blue-800 font-normal line-height-3 px-3 text-white"
              onClick={() =>
                router.push(!user ? "/login" : "/picks/prizepicks/all")
              }
            ></Button>
          </div>
          <div className="flex justify-content-center md:justify-content-end mb-8">
            <Image
              src="/images/screenshots.png"
              alt="Hero Image"
              className="w-9 md:w-auto"
              width="300"
              height="300"
            />
          </div>
        </div>

        <div id="features" className="py-4 px-4 lg:px-8 mt-5 mx-0 lg:mx-8">
          <div className="grid justify-content-center">
            <div className="col-12 text-center mt-8 mb-4">
              <h2 className="text-900 font-normal mb-2">Marvelous Features</h2>
              <span className="text-600 text-2xl">
                Discover tools and insights designed for peak performance.
              </span>
            </div>

            <div className="col-12 md:col-12 lg:col-4 p-0 lg:pr-5 lg:pb-5 mt-4 lg:mt-0">
              <div
                style={{
                  height: "160px",
                  padding: "2px",
                  borderRadius: "10px",
                  background:
                    "linear-gradient(90deg, rgba(253, 228, 165, 0.2), rgba(187, 199, 205, 0.2)), linear-gradient(180deg, rgba(253, 228, 165, 0.2), rgba(187, 199, 205, 0.2))",
                }}
              >
                <div
                  className="p-3 surface-card h-full text-sm"
                  style={{ borderRadius: "8px" }}
                >
                  <div
                    className="flex align-items-center justify-content-center bg-yellow-200 mb-3"
                    style={{
                      width: "3.5rem",
                      height: "3.5rem",
                      borderRadius: "10px",
                    }}
                  >
                    <i className="pi pi-fw pi-users text-2xl text-yellow-700"></i>
                  </div>
                  <h5 className="mb-2 text-900">Easy to Use</h5>
                  <span className="text-600">
                    Intuitive design ensures effortless betting strategy
                    execution.
                  </span>
                </div>
              </div>
            </div>

            <div className="col-12 md:col-12 lg:col-4 p-0 lg:pr-5 lg:pb-5 mt-4 lg:mt-0">
              <div
                style={{
                  height: "160px",
                  padding: "2px",
                  borderRadius: "10px",
                  background:
                    "linear-gradient(90deg, rgba(145,226,237,0.2),rgba(251, 199, 145, 0.2)), linear-gradient(180deg, rgba(253, 228, 165, 0.2), rgba(172, 180, 223, 0.2))",
                }}
              >
                <div
                  className="p-3 surface-card h-full text-sm"
                  style={{ borderRadius: "8px" }}
                >
                  <div
                    className="flex align-items-center justify-content-center bg-cyan-200 mb-3"
                    style={{
                      width: "3.5rem",
                      height: "3.5rem",
                      borderRadius: "10px",
                    }}
                  >
                    <i className="pi pi-fw pi-palette text-2xl text-cyan-700"></i>
                  </div>
                  <h5 className="mb-2 text-900">Fresh Design</h5>
                  <span className="text-600">
                    Sleek, modern design for enhanced user experience.
                  </span>
                </div>
              </div>
            </div>

            <div className="col-12 md:col-12 lg:col-4 p-0 lg:pb-5 mt-4 lg:mt-0">
              <div
                style={{
                  height: "160px",
                  padding: "2px",
                  borderRadius: "10px",
                  background:
                    "linear-gradient(90deg, rgba(251, 199, 145, 0.2), rgba(246, 158, 188, 0.2)), linear-gradient(180deg, rgba(172, 180, 223, 0.2), rgba(212, 162, 221, 0.2))",
                }}
              >
                <div
                  className="p-3 surface-card h-full text-sm"
                  style={{ borderRadius: "8px" }}
                >
                  <div
                    className="flex align-items-center justify-content-center bg-pink-200 mb-3"
                    style={{
                      width: "3.5rem",
                      height: "3.5rem",
                      borderRadius: "10px",
                    }}
                  >
                    <i className="pi pi-fw pi-discord text-2xl text-pink-700"></i>
                  </div>
                  <h5 className="mb-2 text-900">Community</h5>
                  <span className="text-600">
                    Join our vibrant betting community.
                  </span>
                </div>
              </div>
            </div>

            <div className="col-12 md:col-12 lg:col-4 p-0 lg:pr-5 mt-4 lg:mt-0">
              <div
                style={{
                  height: "160px",
                  padding: "2px",
                  borderRadius: "10px",
                  background:
                    "linear-gradient(90deg, rgba(145, 210, 204, 0.2), rgba(160, 210, 250, 0.2)), linear-gradient(180deg, rgba(187, 199, 205, 0.2), rgba(145, 210, 204, 0.2))",
                }}
              >
                <div
                  className="p-3 surface-card h-full text-sm"
                  style={{ borderRadius: "8px" }}
                >
                  <div
                    className="flex align-items-center justify-content-center bg-teal-200 mb-3"
                    style={{
                      width: "3.5rem",
                      height: "3.5rem",
                      borderRadius: "10px",
                    }}
                  >
                    <i className="pi pi-fw pi-shopping-cart text-2xl text-teal-700"></i>
                  </div>
                  <h5 className="mb-2 text-900">Ready to Use</h5>
                  <span className="text-600">Dive into action instantly.</span>
                </div>
              </div>
            </div>

            <div className="col-12 md:col-12 lg:col-4 p-0 lg:pr-5 mt-4 lg:mt-0">
              <div
                style={{
                  height: "160px",
                  padding: "2px",
                  borderRadius: "10px",
                  background:
                    "linear-gradient(90deg, rgba(145, 210, 204, 0.2), rgba(212, 162, 221, 0.2)), linear-gradient(180deg, rgba(251, 199, 145, 0.2), rgba(160, 210, 250, 0.2))",
                }}
              >
                <div
                  className="p-3 surface-card h-full text-sm"
                  style={{ borderRadius: "8px" }}
                >
                  <div
                    className="flex align-items-center justify-content-center bg-blue-200 mb-3"
                    style={{
                      width: "3.5rem",
                      height: "3.5rem",
                      borderRadius: "10px",
                    }}
                  >
                    <i className="pi pi-fw pi-globe text-2xl text-blue-700"></i>
                  </div>
                  <h5 className="mb-2 text-900">Many Bookmakers</h5>
                  <span className="text-600">
                    Wide range of bookmakers integrated.
                  </span>
                </div>
              </div>
            </div>

            <div className="col-12 md:col-12 lg:col-4 p-0 lg-4 mt-4 lg:mt-0">
              <div
                style={{
                  height: "160px",
                  padding: "2px",
                  borderRadius: "10px",
                  background:
                    "linear-gradient(90deg, rgba(160, 210, 250, 0.2), rgba(212, 162, 221, 0.2)), linear-gradient(180deg, rgba(246, 158, 188, 0.2), rgba(212, 162, 221, 0.2))",
                }}
              >
                <div
                  className="p-3 surface-card h-full text-sm"
                  style={{ borderRadius: "8px" }}
                >
                  <div
                    className="flex align-items-center justify-content-center bg-purple-200 mb-3"
                    style={{
                      width: "3.5rem",
                      height: "3.5rem",
                      borderRadius: "10px",
                    }}
                  >
                    <i className="pi pi-fw pi-eye text-2xl text-purple-700"></i>
                  </div>
                  <h5 className="mb-2 text-900">Real Time Updates</h5>
                  <span className="text-600">
                    Regularly updated betting lines.
                  </span>
                </div>
              </div>
            </div>

            <div
              className="col-12 mt-8 mb-8 p-2 md:p-8"
              style={{
                borderRadius: "20px",
                background:
                  "linear-gradient(0deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), radial-gradient(77.36% 256.97% at 77.36% 57.52%, #EFE1AF 0%, #C3DCFA 100%)",
              }}
            >
              <div className="flex flex-column justify-content-center align-items-center text-center px-3 py-3 md:py-0">
                <h3 className="text-gray-900 mb-2">Benjamin Mitchell</h3>
                <span className="text-gray-600 text-2xl">
                  Game-Changer in Betting Strategy
                </span>
                <p
                  className="text-gray-900 sm:line-height-2 md:line-height-4 text-2xl mt-4"
                  style={{ maxWidth: "800px" }}
                >
                  {"Since joining this platform, my betting strategy has transformed. " +
                    "The insights and data provided are second to none, and the community is always supportive." +
                    "I can't imagine placing bets without it anymore!"}
                </p>
                <img
                  src="/images/logo/svg/logo-no-background.svg"
                  className="mt-4"
                  alt="Company logo"
                  style={{ height: "60px" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landing;
